<template>
  <v-container class="supplier-inquiry-page my-4 my-md-8">
    <div v-if="loading['get:api/supplier-inquiry-messages']" class="text-center">
      <v-progress-circular color="primary" class="mx-auto" size="64" width="8" indeterminate />
    </div>

    <SupplierInquiryMessages :supplier-inquiry-hash="$route.params.hash" />
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import SupplierInquiryMessages from '@/components/SupplierInquiryMessages';

export default {
  name: 'SupplierInquiry',

  components: { SupplierInquiryMessages },

  computed: {
    ...mapGetters(['loading']),
  },
};
</script>

<style scoped>
.supplier-inquiry-page {
  max-width: 35em;
}
</style>
